.hideBg::part(--background){
    display:none;
}

button#hideBtn{
    display:none;
}

body.barcode-scanner-active {
  visibility: hidden;
}
ion-content .barcode-scanner-active {
  visibility: hidden;
}
.barcode-scanner-modal {
  visibility: visible;
}

ion-toolbar.toolbar-scan{
  visibility: visible;
  --background: linear-gradient(30deg, #252f64, #81d6a9)!important;
}

.scanner-container-line {
  visibility: visible;
  position: absolute;
  width: 70vw;
  height: 70vw;
  background: transparent;
  border: transparent;
  margin: auto;
}

.scan-line {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  width: 99%;
  height: 3px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  animation: scan-line 1.3s linear infinite alternate;
}

.body-scanner{
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: rgba(212, 25, 25, 0.37)
}

@keyframes scan-line {
  0% {
    top: 0;
}
100% {
    top: 99%;
}
}

.scaner-box{
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 70vw;
  height: 70vw;
  border: 2px solid #fff;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.349);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px; /* Agregamos bordes redondeados */
  background: transparent /* Agregamos un fondo semitransparente */
  }

.texto-scaner{
  visibility: visible;
  text-align: center;
  z-index: 100;
  position: absolute;
  transform: translateY(45vw);
}