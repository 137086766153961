.hero-image img {
    width: 100%;
    height: auto;
    border-bottom: 2px solid #ddd;
  }
  
  .location-info {
    text-align: center;
    margin: 20px 0;
  }
  
  .location-info h1 {
    font-size: 28px;
    margin: 0;
  }
  
  .location-info p {
    font-size: 18px;
    color: gray;
  }
  
  .quick-access-icon {
    margin: 10px 0;
  }
  
  .attractions {
    margin: 20px 0;
  }
  
  .attractions h2 {
    margin: 0 0 10px 10px;
  }
  
  .attractions-scroll {
    display: flex;
    overflow-x: auto;
    padding: 10px;
  }
  
  .attractions-scroll ion-card {
    min-width: 200px;
    margin-right: 10px;
  }
  
  .stories {
    margin: 20px 0;
    padding: 0 10px;
  }
  
  .stories h2 {
    margin: 0 0 10px 0;
  }
  
  .details {
    margin: 20px 0;
    padding: 0 10px;
  }
  
  .details h2 {
    cursor: pointer;
    color: #3880ff;
  }
  
  ion-footer ion-button {
    margin: 10px 0;
  }
  