.icon {
    size: 10px;
}

.icons-star ion-icon {
    color: #efb810;
    font-size: 1.3rem;
    margin: 0px 2px;
}

.starimg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
}