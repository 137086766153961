/* .promotion-service{
    font-size: 1.5rem;
} */

ion-card-subtitle{
    font-size: .8rem;
}

.promotion-type {
    font-weight: lighter;
    text-align:end;
    font-size: .8rem;
    letter-spacing: 2px;
}

.modal-img{
    width: 100%;
    margin: 0;
    height: 40%;
}

.validation {
    margin-top: 5px;
    text-align: center;
}

.promotion {
    font-size: 25px;
    margin: 20px auto;
    width: 250px;
    text-align: center;
}

.button-promotion {
    justify-content: center;
    margin: 50px auto;
    text-align: center;
}

.button-promotion ion-button  {
    /* margin: 40px auto; */
    width: 300px;
    text-transform:uppercase;
    color: rgb(0, 0, 0);
    --border-color: rgb(0, 0, 0);
    --border-width: 2px;
    --background-activated: #006847;
}

.button-promotion img {
    height: 40px;
    width: 40px;
    margin-right: 15px;
}

.details p {
    font-size: 1rem;
}

ion-modal#example-modal {
    --height: 50%;
    --border-radius: 16px;
    --background: #ffff;
    --box-shadow: 0 10px 15px -3px rgba(82, 81, 81, 0.5), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
  
  ion-modal#example-modal::part(backdrop) {
    background: #646464aa;
    opacity: 1;
  }
  
  ion-modal#example-modal ion-toolbar {
    --background: rgb(14 116 144);
    --color: white;
  }

.qr-code{
    height: 250px;
    text-align: center;
    margin: 5px auto;
}

.qr-code img{
    height: 250px;
    width: 250px;
    object-fit: cover;
    object-position: center center;
}

.Codigo-promotion{
    letter-spacing: 8px;
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
}

.Codigo-title {
    font-size: 25px;
    margin: 20px auto;
    width: 250px;
    text-align: center;
}

#example-modal p{
    text-align: center;
    font-size: 15px;
}