.Modal-ingredientes .details{
    display: flex;
    justify-content:space-between;
    margin-top: 10px;
    height: 250px;
}

.details .info{
    width: 250px;
}

.details .info ion-label{
    font-size: 25px;
}

.contenido .description{
    font-size: 14px;
    margin-top: 5px;
}