.leaflet-container{
    font-family: 'Calibri light'!important;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.leaflet-left .leaflet-control{
    margin-left: 13px !important;
}
.leaflet-top .leaflet-control{
    margin-top: 12px !important;
}