  
  ion-content#main-content{
    display: flex;
    justify-content: center;
    align-content: flex-end;
    align-items: end;
  }
  
  .arriba {
    position: absolute;
    z-index: 100;
  }
  
  
  .fondomodal{
    height: 30px;
    width: 100vh;
    z-index: 20;
  }
  
  ion-searchbar.custom {
    margin: 0px;
    padding-right: 32px;
    --border-radius: 30px;
    --placeholder-color: #006847;
    --placeholder-font-weight: bold;
    --icon-color: #006847;
    --placeholder-opacity: 1;
    --box-shadow: 9px 10px 10px -12px rgba(0,0,0,0.79);
    --background: rgb(255, 255, 255);
  }
  
  img .cubrir{
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  ion-button.ScanerBtn{
    margin-right: 16px;
    /* background-color: transparent; */
  }
  
  ion-toolbar.custom{
    /* position: absolute; */
    height: 60px;
    /* --padding-top: 0px; */
    /*--background: rgb(246, 246, 246);*/
    --background: linear-gradient(30deg, #172468, #1e854c);
    
  }

.elements-tab1{
  display: flex;
  justify-content: space-between;
  height: 70px;
}

.custom .QR-button{
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom .QR-button ion-icon{
  color: #3C9666;
}

.custom .nombre-marca{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  text-align: center;
  /*background: #252F64;
  background: -webkit-linear-gradient(to left, #252F64 0%, #3C9666 100%);
  background: -moz-linear-gradient(to left, #252F64 0%, #3C9666 100%);
  background: linear-gradient(to left, #252F64 0%, #3C9666 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;*/
  color: white;
  font-family: 'Calibri light';
}

.nombre-marcab{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    text-align: center;
    color: white;
    font-family: 'Calibri light';
}

.custom .nombre-marca h1{
  font-size: 30px;
  font-size-adjust: 0.5;
  font-weight: 900;
}

.custom .idiomas{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #252F64;
  font-size: 20px;
  font-weight: 800;
} 

  .hideBgfMap::part(background){
    display:none;
}

capacitor-google-map {
  position: absolute;
  width: 100%;
  height: 100%;
}

capacitor-google-map {
  position: absolute;
  width: 100%;
  height: 100%;
}

capacitor-google-map button {
  display: none !important;
}

capacitor-google-map div {
  background-color:  transparent !important;
  box-shadow: rgba(0,0,0,0) 0px 0px 0px 0px !important;
}

.paddingt_10{
  --padding-top: 15px !important;
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 22px;
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}

.slide-content-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide-image-flex {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.slide-labels-flex {
  background-color: white;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.slide-labels-flex h3 {
  margin: 5px;
  font-size: 1.3em;
}

.slide-labels-flex h4 {
  margin: 5px;
  font-size: 1.1em;
}

.slide-labels-flex p {
  margin: 5px 0 0;
  font-size: 1em;
}
