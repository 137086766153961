img.tamanioIMG{
  width: 100px;
  height: 75px;
  margin-right: 8px;
  border-radius: 10px;
  /*object-fit: contain;*/
}

ion-item.Itemcfg{
  --padding-start:8px;
}

.Sinpadding{
padding: 0px;
}

.Modal {
  --max-height: 50vh;
  --border-radius: 25px 25px 0px 0px;
  --box-shadow: 0px -8px 11px -4px rgba(0,0,0,0.42);
}

.linea{
  width: max-content;
  height: 1px;
  background-color: #fff;
}

p#desc{
  display: flex;
  align-items: center;
  font-size : 13px !important;
}

.margintb_10{
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

@media screen and (max-width: 381px){
  p#desc{
    font-size: 9px !important;
  }
}
@media screen and (max-width: 700px){
  p#desc{
    font-size: 10.5px !important;
  }
}

div.toolbar-container {
  --margin-top: 10px !important;
  --margin-bottom: 10px !important;
}
