ion-toolbar#modalMarkers{
    height: 1rem !important;
}

.imgintro {
    height: 250px !important;
    object-fit: cover;
}

#icon-mxprof{
    width: 58px;
    height: 58px;
}

.padimagen{
    padding-top: 20px;
}


.simple-icon{
    margin: 0px !important;
    background-color: aliceblue !important;
    background: aliceblue !important;
    display: flex !important;
}