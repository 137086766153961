ion-tab-bar {
    /* --color-selected: #006847; */
    --color-selected: black;
    --color: gray;
}

ion-card-header {
    padding-top: 7px;
    padding-bottom: 0px;
}

p {
    text-align: justify;
}

