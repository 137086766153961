/* PhotosModal.css */

.photos-modal-content {
    --ion-padding-start: 0;
    --ion-padding-end: 0;
    --ion-padding-top: 0;
    --ion-padding-bottom: 0;
  }
  
  .photos-modal-grid {
    padding: 10px;
  }
  
  .photo-col {
    padding: 5px;
  }
  
  .photo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.2s ease-in-out;
  }
  
  .photo-img:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  