ion-toolbar.profileToolbar{
    --background: white;
    margin-top: 4vh;
  }

ion-title.nombre-marca{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    font-weight: 900;
    color: transparent; /* Hacemos el texto transparente */
    background: linear-gradient(30deg, #252f64, #3c9666); /* Definimos el gradiente */
    background-clip: text; /* Indicamos que el gradiente debe llenar el texto */
    -webkit-background-clip: text; /* Para navegadores basados en WebKit */
}
.fondo-boton-profile{
    --background: linear-gradient(30deg, #252f64, #3c9666);
    width: 40vw;
}

.avatar {
    height: 220px;
    width: 200px;
    border-radius:50%;
}
.content-img{
    width: 100vw;
    text-align: center;
    padding-top: 5vh;
}
.text-profile{
    font-size: 1.5rem;
    font-weight: bold;
    color: rgba(128, 128, 128, 0.582);
}
.text-profile-sub{
    font-size: 0.8rem;
    color: gray;
}
.acomodar-profile{
    display: flex;
    flex-direction: column; /* Establecemos la dirección como columna */
    align-items: center;
}

.text-profile-info{
    font-size: 1rem;
    font-weight: 530;
    color: rgb(65, 64, 64);
}