:root {
    --swiper-navigation-size: 30px;
    --swiper-theme-color: #007aff;
}
.imgSlide {
    height: 40vh;
    margin-bottom: 40px;
}

.SwiperFull{
    height: 100vh;
    /* height: 100%; */
    width: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    display: flex;
    /* z-index: 200; */
}


.dispocicionSlider{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 10px;
}

.img-swiper{

}

.button-swiper{
    position: absolute;
    bottom: 80px;
    
}

ion-button.button-intro{
    height: 40px;
    font-weight: bold;
    
    font-size: 20px;
    --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
}