
ion-content#Slide1 .content{
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 100%;
}

ion-content#Slide1 {
    background-color: black;
}

ion-content#Slide1 video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

ion-content#Slide1 .content .img2{
    /* position: absolute; */
}


ion-content#Slide1 .content .leyenda{
    position: absolute;
    color: #fff;
    font-weight:lighter;
    font-size: 3rem;
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.8);
    /* width: 90%; */
    top: 85%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
}
