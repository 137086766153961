ion-tab-button[hidden] {
    display: none;
  }

  .degradado{
    --background: transparent;
    background: linear-gradient(30deg, #172468, #1e854c);
    height: 70px;
  }

.acomodo-tab{
  --padding-bottom: 5px;
  --padding-top: 5px;
  font-size: 1rem;
}

ion-toolbar.header-titles{
  height: 60px;
}