/* .searchbar {
    margin-top: 20px;
    margin-bottom: 50px;
}

ion-title.title-large {
    color: #c897d8;
    font-size: 30px;
}

ion-title{
    --color: #006847
    
}

ion-searchbar.custom {
    --background: #fff;
    --color: #000000;
    --placeholder-color: #006847;
    --icon-color: #006847;
    --clear-button-color: #000000;
    --border-radius: 20px;
    --margin-top: 50px;
} */

ion-title.title-large {
    color: #c897d8;
    font-size: 50px;
  }
  
  ion-title {
    --color: #fff;
    font-size: 30px;
    /* font-weight: 600; */
  }
  
  ion-toolbar {
    --background: #006847;
  }