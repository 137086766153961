.padding-left-lng{
    padding-right: 30px;
}
.color-toolbar-login{
    background: white;
    padding-right: 12px;
   }
   div .line{
    height: 2px;
    border-top-width: 1px;
    flex-grow: 1;
    background-color: rgb(182, 182, 182);
    margin: 0 0.5cm;
   }
   
   div .container{
       display: flex;
       align-items: center;
       position: relative;
       padding-left: 0.5cm !important;
       padding-right: 0.5cm !important;
   }
   
   .margenH{
       margin-left: 16px;
       margin-right: 16px;
   }
   ion-button .padd{
       --padding-top: 100px;
       --padding-bottom: 100px;
     }
   
   .text{
       line-height: 1.4em;
       font-size: 1.2em;
       color: #3f3e3e;
       background: #fff;
       text-align: center;
       padding-left: 0.5cm;
       padding-right: 0.5cm;
   }
   
   .margin-text-login{
    margin-bottom: 7rem;
   }
   
   .title{
       font-size: 1.9em;
       font-weight: bolder;
       color: transparent; /* Hacemos el texto transparente */
       background: linear-gradient(30deg, #252f64, #3c9666); /* Definimos el gradiente */
       background-clip: text; /* Indicamos que el gradiente debe llenar el texto */
       -webkit-background-clip: text; /* Para navegadores basados en WebKit */
       margin-top: 26px;
   }
   .link{
       /*text-decoration: none;*/
       font-weight: 700;
       color: transparent; /* Hacemos el texto transparente */
       background: linear-gradient(30deg, #252f64, #81d6a9); /* Definimos el gradiente */
       background-clip: text; /* Indicamos que el gradiente debe llenar el texto */
       -webkit-background-clip: text; /* Para navegadores basados en WebKit */
   }
   
   .botoncustom {
       margin: 16px;
       --vertical-align: middle;
       font-size: 27px;
       --background: linear-gradient(45deg,#252f64, #81d6a9);
       padding-left: 0.5cm;
       padding-right: 0.5cm;
       --padding-top: 9px;
       --padding-bottom: 9px;
       --border-radius: 15px;
     }

     ion-footer#footertransp{
        background-color: transparent;
        padding: 0px;
     }

     .Inputs-login{
       padding-left: 0.5cm !important;
       padding-right: 0.5cm !important;
       --border-radius: 15px !important;
     }

    select#country option[value="Mexico"] {
        background-image: url('../assets/flag-mexico.svg');
    }

    .contenedor-flags{
        width: 90%;
        margin: auto;
        /* padding: 40px 0; */
    }

.select-box{
    width: 200px;
    margin: auto;
    position: relative;
}
.select {
    background: #ffffff;
    width: 200px;
    box-shadow: 0px 0px 0px rgba(0,0,0,.16);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .2s ease all;
    /* margin-bottom: 30px; */
    /* padding: 10px; */
    position: relative;
    z-index: 200;
    width: 200px;
    border: 2px solid #3c9666;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    -webkit-transition: .2s ease all;
    -moz-transition: .2s ease all;
    -ms-transition: .2s ease all;
    -o-transition: .2s ease all;
}

.select.active, 
.select:hover{
    box-shadow: 0px 5px 10px rgba(0,0,0,.16);
    border: 2px solid #213f8f;
}

.select.active:before{
    content: "";
    display: block;
    height: 0;
    width: 0;
    border-top: 15px solid #213f8f;
    border-right: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid transparent;
    position: absolute;
    bottom: -30px;
    left: calc(50% - 15px );
}

.select ion-icon{
    font-size: 20px;
    margin-right: 10px;
    margin-top: 10px;
    left: 0;
    margin-left: 0;
    color: #213f8f;
}

.select .titulo{
    margin-bottom: 10px;
    color: #000;
    font-weight:lighter;
    font-size: 20px;
    margin-left: 15px;

}

a{
    text-decoration: none;
    color: #000;
}

.opciones {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0,0,0,.16);
    max-height: 200px;
    overflow: auto;
    z-index: 100;
    width: 100%;
    display: none;
    margin-top: 15px;
    
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
    .opciones.active{
        display: block;
    }

    /*Animacion*/
.contenido-opcion{
    width: 100%;
    display: flex;
    align-items: center;
    /* border-bottom: 2px solid #213f8f; */
    transition: .2s ease all;
    /* padding: 30px;  */

    -webkit-transition: .2s ease all;
    -moz-transition: .2s ease all;
    -ms-transition: .2s ease all;
    -o-transition: .2s ease all;

}


.contenido-opcion img{
    width: 30px;
    height: 30px;
    margin-right: 20px;
    margin-left: 10px;
}

.opciones .contenido-opcion:hover{
    background: #213f8f;
    /* color: #fff; */
}

.opciones .contenido-opcion:hover .titulo{
    color: #fff;
}

ion-select{
    --highlight-color-focused: transparent;
    --placeholder-opacity: 1;
}

ion-popover {
    --width: 170px;
  }