.swiper {
  bottom: -40px;
}

.mySwiper{
   
    display: relative;
    transform: translateY(-23vh);
    padding-bottom: 7vh;
    /*z-index: 200;*/
  }

  .swiper .swiper-slide div.icono-swiper-tab1{
    width: 80px !important;
    height: 80px !important;
    border-radius: 100px;
    text-align: center;
    font-weight: 900;
    font-size: 14px;
    box-shadow: 3px 3px 5px gray;
  }
  
  button.swipe{
    background-color: transparent;
  }

.ajustar-icono{
  width: 65px !important;
  height: 65px !important;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}

.ajustar-iconoyuc{
  width: 72px !important;
  height: 79px !important;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}

.text-icono{
  color: #29346e; 
  padding-top: 20px;
}

.swiper .swiper-pagination-bullet-active{
  background: green;
}
.swiper .swiper-pagination-bullet{
  background: green;
}

.imgIconoCarrusel{
  width: 100% !important;
  height: 100% !important;
  background-size: 100% 100%;
}

.marginb_25{
  margin-bottom: 25px;
}

.icono-color-arqueologia{
  background:#FAD325;
}
.icono-color-tours{
  background:#42C7C5;
}
.icono-color-artesanias{
  background:#FA82AE;
}
.icono-color-comida{
  background:#FC8C30 ;
}
.icono-color-museos{
  background: #AA65BF ;
}
.icono-color-transporte{
  background:#4FAB7A ;
}
.icono-color-hospedaje{
  background:#E33B3B;
}

.icono-color-cooperativa{
  background:#C67D58;
}

.icono-color-pueblomagico{
  background:#3851ef ;
}

.icono-color-maya-kaan{
  background:#f0e8e8 ;
}

.Arqueologia{
  --background: linear-gradient(to right, rgb(210, 108, 5), #FAD325);
}
.Tours{
  --background: linear-gradient(to right, #03486f, #42C7C5);
}
.Artesanias{
 --background: linear-gradient(to right, #e4007c, #FA82AE);
}

.Restaurantes{
  --background: linear-gradient(to right, #f73c1b, #FC8C30);
}

.Hospedajes{
  --background: linear-gradient(to right,#960404,#E33B3B);
}

.Museos{
  --background: linear-gradient(to right, #650460, #AA65BF);
}