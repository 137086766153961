/* ion-card#cardArt {
    height: 25%;
} */

img#icon-close{
    width: 35px;
    padding: 0;
}

.text-close{
    color: #000;
    font-weight: bold;
}

ion-card#cardArt img{
    object-fit: cover;
    object-position: 50% 50%;
}

.header-modal-img-tour{
  width: 100%;
  height: auto;
  object-fit: cover;
  height: 200px;
  background-color: black;
  z-index: 1;
}

ion-modal .toolbar-modal-tour {
    --background: #014F86;
    --color: white;
    text-align: center;
}

ion-modal .toolbar-modal-tour ion-title .title{
    display: flex;
    justify-content: space-between;
    color: #fff;
    width: 100%;
    background-color: transparent;
    /* font-size: 1.5rem; */
    line-height: 2.5rem;
    margin: 5px 0;
    justify-content: right;
}

ion-modal .toolbar-modal-tour ion-title .title .precio{
    font-size: 1.1rem;
    font-weight: lighter;
    display: inline-flex;
    align-items: center;
    justify-content: right;
}

.precio .moneda{
    text-transform: uppercase;
    margin-left: 2px;
    font-size: .7rem;
    justify-content: end;
    bottom: 0;
}

.localidad {
    /* margin-top: 5px; */
    display: inline-flex;
    align-items: center;
    justify-content: left;
    width: 250px;
    left: 0;
    /* width: 100%; */
}

.icon-loc {
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 2px;
}
  
.lugar {
    /* width: 90%; */
    font-size: 4vw;
    align-items: center;
  }
  
ion-card#swiper-ioncard{
    width: 100%;
}

ion-card#swiper-ioncard .img-swiper{
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.title-incluye{
    font-size: 20px;
    font-weight: bolder;
}

.text-close-tour{
    color: #fff;
    font-weight: bold;
}

.padd-incluye{
    padding-top: 25px;
}


