ion-progress-bar::part(track) {
    background: #D9D9D9;
  }
  
ion-progress-bar::part(progress) {
    background: #EFB810;
    height: 20px;
  }

.ion-header-tab3 {
  /*background-color: #006847;*/
  background: linear-gradient(45deg, rgba(37,47,100,1) 20%, rgba(60,150,102,1) 80%);
}