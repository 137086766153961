.rewards-titles ion-card-title{
    color: #000000;
    font-size: 3.5rem;
}

.rewards-titles ion-card-subtitle {
    font-size: 1.2rem;
}

.star-img {
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
}


.container_p{
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

.section_title p{
    font-weight: 500;
    font-size: 22px;
    line-height: 1.5;
    color: #fd6e0a;
}

.section_title h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 1.5;
    text-transform: capitalize;
    color: #15295f;
}

.section_title.center {
    text-align: center;
}

.experience-section {
    padding: 65px 0 100px;
    background-color: #fdf8f7;
    position: relative;
}

.experience-section .experience-item {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.experience-item {
    flex: 0 0 48%;
    max-width: 48%;
}

.experience-item p{
    color: black;
}

.experience-info {
    display: flex;
    justify-content: space-between;
}

.experience-info p {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #15295f;
    opacity: 1;
    animation: showText 0.5s 1s linear forwards;
    -webkit-animation: showText 0.5s 1s linear forwards;
}

.progress-line {
    margin-top: 7px;
    height: 10px;
    width: 100%;
    background-color: #cdcdcd;
    border-radius: 50px;
    position: relative;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.progress-line span{
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    animation: animate 1s cubic-bezier(1,0,0.5,1) forwards;
    height: 100%;
    border-radius: 50px;
    position: absolute;
    background-color: #EFB810;
    transition: 1s all;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    -webkit-animation: animate 1s cubic-bezier(1,0,0.5,1) forwards;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    -webkit-transition: 1s all;
    -moz-transition: 1s all;
    -ms-transition: 1s all;
    -o-transition: 1s all;
}

.progress-line span.animate{
    animation: 1.5s 0.5s cubic-bezier(1,0,0.5,1) forwards;
    -webkit-animation: 1.5s 0.5s cubic-bezier(1,0,0.5,1) forwards;
}

@-webkit-keyframes showText{
    100%{
        opacity: 1;
    }
}

@keyframes showText{
    100%{
        opacity: 1;
    }
}

@-webkit-keyframes animate{
    100%{
        transform: scaleX(1);
        -webkit-transform: scaleX(1);
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -o-transform: scaleX(1);
}
}

@keyframes animate{
    100%{
        transform: scaleX(1);
        -webkit-transform: scaleX(1);
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -o-transform: scaleX(1);
}
}

