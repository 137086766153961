/* ActivitiesModal.css */

.activities-modal-content {
    --ion-padding-start: 16px;
    --ion-padding-end: 16px;
    --ion-padding-top: 16px;
    --ion-padding-bottom: 16px;
    background-color: #f9f9f9;
  }
  
  .activity-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .activity-card img {
    border-radius: 8px;
    max-height: 100px;
    object-fit: cover;
    margin-bottom: 12px;
  }
  
  .activity-card-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
  }
  
  .activity-card-description {
    font-size: 1em;
    line-height: 1.5;
    color: #444;
  }
  
  .ion-button {
    --background: #007bff;
    --background-focused: #0069d9;
    --background-hover: #0056b3;
    --background-activated: #004085;
    --color: white;
    margin-top: 16px;
  }
  