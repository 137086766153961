
.navire{background:url(https://res.cloudinary.com/dioieuprs/image/upload/v1471359656/navire_n02z6s.png) no-repeat; background-size:100% auto; width:120px; height:100px; position:absolute; top:-50px; right:-130px;-webkit-transition: right 0.2s ease; transition: right 0.2s ease; }

.mainMenuOverlay.open .navire{right:70%;-webkit-transition: right 28s ease 1s; transition: right 28s ease 1s;}
/* ### main Menu Overlay */
.mainMenuOverlay {
  background-color: rgba(34, 152, 195, 0.64);
  position: fixed;left: 0;right: 0;bottom: -420px;z-index: 999;
  height: 420px;
  box-shadow: 0 0 15px -3px #03374A;
  border-radius: 100% 100% 0 0 / 14% 14% 0 0;
  -webkit-transition: bottom 0.5s ease; transition: bottom 0.5s ease;
}

.mainMenuOverlay.open { bottom: 0; }

.mainMenuOverlay .toggleMenu {
  display: block;
  /*background: url(https://res.cloudinary.com/dioieuprs/image/upload/v1466688705/floating-menu/sandwich.png) no-repeat center center #65B5D0;background-size: 23px auto;*/
  background-color:#bbbbbb;
  border: 1px solid #FFF; border-radius: 80px;
  width: 62px;height: 62px;
  position: absolute;top: -12px;left: 50%;
  margin: -31px 0 0 -31px;
  cursor: pointer;
  font-size:24px; color:#FFF; text-align:center; line-height:62px;
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2) inset, 0 0 16px -4px #063343;
  /*-webkit-transition: box-shadow 0.5s ease, top 0.5s ease; transition: box-shadow 0.5s ease, top 0.5s ease;*/
}

.mainMenuOverlay.open .toggleMenu { top: 50%; background-color: #bbbbbb;}

.mainMenuOverlay .itemMenuBox {
  background: url(https://res.cloudinary.com/dioieuprs/image/upload/v1466688705/floating-menu/go2.png) no-repeat center center;background-size: 28px auto;
  position: absolute; top: 50%; left: 50%;
  margin: -31px 0 0 -31px;
  height: 62px; width: 142px;
  text-align: right;
  border-radius: 40px;
  -webkit-transform-origin: 31px 31px;
  -ms-transform-origin: 31px 31px;
  transform-origin: 31px 31px;
  /*-webkit-transition: all 1s ease 0.4s;*/
  transition: all 1s ease 0.4s;
}

.mainMenuOverlay.open .itemMenuBox {width: 182px; -webkit-transition: all 1s ease 0s; transition: all 1s ease 0s;
}

.mainMenuOverlay .itemMenuBox.bills {-webkit-transform: rotate(270deg); -ms-transform: rotate(270deg);  transform: rotate(270deg);
}

.mainMenuOverlay .itemMenuBox.tarsheed {-webkit-transform: rotate(330deg);  -ms-transform: rotate(330deg);  transform: rotate(330deg);
}

.mainMenuOverlay .itemMenuBox.employees {-webkit-transform: rotate(30deg);  -ms-transform: rotate(30deg); transform: rotate(30deg);
}

.mainMenuOverlay .itemMenuBox.location {-webkit-transform: rotate(90deg); -ms-transform: rotate(90deg); transform: rotate(90deg);
}

.mainMenuOverlay .itemMenuBox.eservices {-webkit-transform: rotate(150deg); -ms-transform: rotate(150deg);  transform: rotate(150deg);
}

.mainMenuOverlay .itemMenuBox.contact {-webkit-transform: rotate(210deg); -ms-transform: rotate(210deg);  transform: rotate(210deg);
}

.mainMenuOverlay .itemMenuBox .itemMenu {
  display: inline-block;
  border: 2px solid rgba(255,255,255,0.6);  border-radius: 40px;
  background-color: #1f97c2;  background-repeat: no-repeat; background-position: center center;
  width: 62px;height: 62px;
  border-radius: 40px;
  transition: all 0.8s ease;
  color:#FFF; font-size:28px; line-height:64px; text-align:center;
}

.mainMenuOverlay .itemMenuBox .itemMenu1 {
    display: inline-block;
    border: 2px solid rgba(255,255,255,0.6);  border-radius: 40px;
    background-color: #FAD325;  background-repeat: no-repeat; background-position: center center;
    width: 62px;height: 62px;
    border-radius: 40px;
    transition: all 0.8s ease;
    color:#FFF; font-size:28px; line-height:64px; text-align:center;
  }
  
  .mainMenuOverlay .itemMenuBox .itemMenu2 {
    display: inline-block;
    border: 2px solid rgba(255,255,255,0.6);  border-radius: 40px;
    background-color: #42C7C5;  background-repeat: no-repeat; background-position: center center;
    width: 62px;height: 62px;
    border-radius: 40px;
    transition: all 0.8s ease;
    color:#FFF; font-size:28px; line-height:64px; text-align:center;
  }
  
  .mainMenuOverlay .itemMenuBox .itemMenu3 {
    display: inline-block;
    border: 2px solid rgba(255,255,255,0.6);  border-radius: 40px;
    background-color: #FA82AE;  background-repeat: no-repeat; background-position: center center;
    width: 62px;height: 62px;
    border-radius: 40px;
    transition: all 0.8s ease;
    color:#FFF; font-size:28px; line-height:64px; text-align:center;
  }
  
  .mainMenuOverlay .itemMenuBox .itemMenu4 {
    display: inline-block;
    border: 2px solid rgba(255,255,255,0.6);  border-radius: 40px;
    background-color: #FC8C30;  background-repeat: no-repeat; background-position: center center;
    width: 62px;height: 62px;
    border-radius: 40px;
    transition: all 0.8s ease;
    color:#FFF; font-size:28px; line-height:64px; text-align:center;
  }
  
  .mainMenuOverlay .itemMenuBox .itemMenu5 {
    display: inline-block;
    border: 2px solid rgba(255,255,255,0.6);  border-radius: 40px;
    background-color: #AA65BF;  background-repeat: no-repeat; background-position: center center;
    width: 62px;height: 62px;
    border-radius: 40px;
    transition: all 0.8s ease;
    color:#FFF; font-size:28px; line-height:64px; text-align:center;
  }

  .mainMenuOverlay .itemMenuBox .itemMenu6 {
    display: inline-block;
    border: 2px solid rgba(255,255,255,0.6);  border-radius: 40px;
    background-color: #C67D58;  background-repeat: no-repeat; background-position: center center;
    width: 62px;height: 62px;
    border-radius: 40px;
    transition: all 0.8s ease;
    color:#FFF; font-size:28px; line-height:64px; text-align:center;
  }
  
  
.mainMenuOverlay .itemMenuBox.bills .itemMenu1 {
  /*background-image: url(https://res.cloudinary.com/dioieuprs/image/upload/v1466688705/floating-menu/file.png);
  background-size: 20px auto;*/
  -webkit-transform: rotate(90deg); -ms-transform: rotate(90deg); transform: rotate(90deg);
}


.mainMenuOverlay .itemMenuBox.tarsheed .itemMenu2 {
  /* background-image: url(https://res.cloudinary.com/dioieuprs/image/upload/v1466688705/floating-menu/tarsheed.png); 
  background-size: 38px auto;*/
  -webkit-transform: rotate(30deg); -ms-transform: rotate(30deg); transform: rotate(30deg);
}

.mainMenuOverlay .itemMenuBox.employees .itemMenu3 {
  /*background-image: url(https://res.cloudinary.com/dioieuprs/image/upload/v1466688705/floating-menu/employees.png);
  background-size: 38px auto;*/
  -webkit-transform: rotate(330deg);-ms-transform: rotate(330deg);transform: rotate(330deg);
}



.mainMenuOverlay .itemMenuBox.location .itemMenu4 {
  /*background-image: url(https://res.cloudinary.com/dioieuprs/image/upload/v1466688705/floating-menu/marker.png);
  background-size: 24px auto;*/
  -webkit-transform: rotate(270deg);  -ms-transform: rotate(270deg);  transform: rotate(270deg);
}



.mainMenuOverlay .itemMenuBox.eservices .itemMenu5 {
  /*background-image: url(https://res.cloudinary.com/dioieuprs/image/upload/v1466688705/floating-menu/mouse.png);
  background-size: 32px auto;*/
  -webkit-transform: rotate(210deg);  -ms-transform: rotate(210deg);  transform: rotate(210deg);
}



.mainMenuOverlay .itemMenuBox.contact .itemMenu6 {
  /*background-image: url(https://res.cloudinary.com/dioieuprs/image/upload/v1466688705/floating-menu/phone.png);
  background-size: 19px auto;*/
  -webkit-transform: rotate(150deg);  -ms-transform: rotate(150deg);  transform: rotate(150deg);
}

/* Hover */
.mainMenuOverlay .itemMenuBox.bills .itemMenu1:hover {
    -webkit-transform: rotate(450deg);  -ms-transform: rotate(450deg);  transform: rotate(450deg);
}

.mainMenuOverlay .itemMenuBox.tarsheed .itemMenu2:hover {
    -webkit-transform: rotate(390deg);  -ms-transform: rotate(390deg);  transform: rotate(390deg);
}

.mainMenuOverlay .itemMenuBox.employees .itemMenu3:hover {
    -webkit-transform: rotate(690deg);  -ms-transform: rotate(690deg);  transform: rotate(690deg);
}

.mainMenuOverlay .itemMenuBox.location .itemMenu4:hover {
    -webkit-transform: rotate(630deg);  -ms-transform: rotate(630deg);  transform: rotate(630deg);
}

.mainMenuOverlay .itemMenuBox.eservices .itemMenu5:hover {
    -webkit-transform: rotate(570deg);  -ms-transform: rotate(570deg);  transform: rotate(570deg);
}

.mainMenuOverlay .itemMenuBox.contact .itemMenu6:hover {
    -webkit-transform: rotate(510deg);  -ms-transform: rotate(510deg);  transform: rotate(510deg);
}



.floating{
    -webkit-animation-name: Floatingx;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-name: Floating;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
}

@-webkit-keyframes Floatingx{
    from {-webkit-transform:translate(0, 0px);}
    65% {-webkit-transform:translate(0, 5px);}
    to {-webkit-transform: translate(0, -0px);    }    
}
    
@-moz-keyframes Floating{
    from {-moz-transform:translate(0, 0px);}
    65% {-moz-transform:translate(0, 5px);}
    to {-moz-transform: translate(0, -0px);}    
}

.floating2{
    -webkit-animation-name: Floatingx2;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-name: Floating2;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
}

@-webkit-keyframes Floatingx2{
    from {-webkit-transform:translate(0, 0px);}
    45% {-webkit-transform:translate(0, 8px);}
    to {-webkit-transform: translate(0, -0px);    }    
}
    
@-moz-keyframes Floating2{
    from {-moz-transform:translate(0, 0px);}
    45% {-moz-transform:translate(0, 8px);}
    to {-moz-transform: translate(0, -0px);}    
}

.floating3{
    -webkit-animation-name: Floatingx3;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-name: Floating3;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
}

@-webkit-keyframes Floatingx3{
    from {-webkit-transform:translate(0, 0px);}
    50% {-webkit-transform:translate(2px, 4px);}
    to {-webkit-transform: translate(0, -0px);    }    
}
    
@-moz-keyframes Floating3{
    from {-moz-transform:translate(0, 0px);}
    50% {-moz-transform:translate(2px, 4px);}
    to {-moz-transform: translate(0, -0px);}    
}



/* signature */
.signatureBox{text-align:right; padding:4px;}
.signatureBox.fixed{ position:fixed; bottom:8px; right:8px; }
.signature, .signature a{color:#484848; }
.signature:before, .signature:after{ display:inline-block; overflow:hidden; vertical-align: bottom; -webkit-transition: all 0.5s; -webkit-transition: all 0.5s; width:11px}
.signature:before{content:'Mahmoud';}
.signature:after{content:'NBET'; margin-left:0px;width:10px}

.signature:hover{color:#484848;}
.signature:hover:before{width:76px;}
.signature:hover:after{width:34px; margin-left:3px;}

.ajustar-icono-circ{
    width: 50px !important;
    height: 50px !important;
    padding-top: 5%;
    padding-bottom: 5%;
    text-align: center;
  }
  
  .ajustar-icono-circ1{
    width: 50px !important;
    height: 50px !important;
    padding-top: 5%;
    padding-bottom: 5%;
    text-align: center;
  }

  .ajustar-icono-prin{
    width: 65px !important;
    height: 60px !important;
    padding-top: 0%;
    padding-bottom: 0%;
    text-align: center;
  }
