ion-thumbnail.foodCard{
    --size: 85px;
    --border-radius: 14px;
    --border-top: 25px;
}

.margin-image ion-avatar img{
    overflow: hidden !important;
    border: 1px solid rgb(0, 0, 0) ;
    background: linear-gradient(45deg, #ffcc00, #ff3300); /* Gradiente de fondo */
}

/* .margin-image{
  border-style: solid;
  border-width: 10px;
  border-image: linear-gradient(45deg, rgb(143, 55, 0), rgb(66, 228, 250)) 1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
} */

.circular-square {
    object-fit: cover;
}

.image-container {
    width: 100px; /* Ajusta el tamaño del contenedor según tus necesidades */
    height: 100px;
    position: relative; /* Necesario para el uso de pseudo-elementos */
    overflow: hidden;
    border-radius: 50%; /* Esto hace que el contenedor y la imagen sean circulares */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circular-border {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    object-fit: cover;
    /*Ajustaeltamañodelaimagenparallenarelcontenedor*/
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
  
.active  .image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgb(37,47,100);
    background: linear-gradient(245deg, rgba(37,47,100,1) 20%, rgba(60,150,102,1) 80%);
    z-index: -1; /* Colocar el pseudo-elemento detrás de la imagen */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Sombra opcional */
}




.block {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
ion-modal#ion-modal-info {
    --height: 50%;
    --border-radius: 16px;
    background: rgba(73, 73, 73, 0.493);
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    align-items: end;
}

ion-modal#ion-modal-info::part(backdrop) {
    background: rgb(0, 58, 145);
    opacity: 1;
  }
  