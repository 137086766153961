/* ion-card {
    --background: #ffffff;
    --color: #081e1b;
  } */

  
img.CardRes {
    width: 100%;
    height: 6rem;
    object-fit: cover;
    
    /* position: absolute; */
  }

.title-restaurant-tab2 {
  background: #252F64;
  background: linear-gradient(to left, #252F64 0%, #3C9666 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container {
  margin-top: 5px;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.ubicacion {
  width: 90%;
  font-size: 1.5rem;
  align-items: center;
}

.icon {
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 5px;
}

ion-card-content {
  width: 100%;
  display: inline-flex; 
  align-items: center; /* Para alinear items flexibles respecto al eje secundario */
  justify-content: space-between;
}

ion-card-content .score {
  width: 115px;
}

/* ion-header {
  text-align: center;
  background-color: black;
}

ion-toolbar {
  background-color: rgb(190, 19, 19);
} */

.toolbar-modal{
  text-align: center;
}

.header-modal-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  height: 200px;
  opacity: 0.7;
  background-color: black;
}

ion-modal .toolbar-modal {
  --background: rgb(0, 0, 0);
  --color: white;
}

ion-title#TituloRestaurant {
  /* position: absolute; */
  
  top: 50%;
  text-align: center;
}

.button-close{
  position: absolute;
  right: 0;
  top: 1rem;
}