
   ion-button .padd{
       --padding-top: 100px;
       --padding-bottom: 100px;
     }
   
   .title{
       font-size: 1.9em;
       font-weight: bolder;
   }

     .Color-degradado{
        --background: linear-gradient(30deg, #252f64, #3c9666) !important;
     }